import { graphql, Link, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import Buttons from "../common/button"

export const EbookScard = props => {
  const { title, chapterNumber, excerpt, slug, image } = props.cardInfo
  return (
    <Link to={`/resources/templates/${slug}`}>
      <div className="e-book-card">
        <div className="prelative overflow-hidden mb30 br-10 img-wrap">
          <img src={image?.url} className="book-img br-10 w-100" alt="img" />
        </div>
        <div className="desc-box text-start">
          <p className="p18 deep-purple fw-600 fs-20">{title}</p>
          <p className="p16 text-grey">{excerpt?.text}</p>
        </div>
      </div>
    </Link>
  )
}
const EbookCardGroup = props => {
  const [itemsToBeShown, setItemsToBeShown] = useState(6)

  const [listCount, setListCount] = useState(0)
  var currentItem = 0

  return (
    <>
      <StaticQuery
        query={graphql`
          query CardsEbook {
            SuperOps {
              resources(
                where: { categoryDropdown: One_million }
                orderBy: createdAt_DESC
              ) {
                categoryDropdown
                title
                chapterNumber
                excerpt {
                  text
                }
                slug
                image {
                  url(
                    transformation: { document: { output: { format: webp } } }
                  )
                }
              }
            }
          }
        `}
        render={data => (
          <Row className="rg-56">
            {setListCount(data.SuperOps.resources.length)}
            {data.SuperOps.resources.map(carddata => {
              const { title, chapterNumber, excerpt, slug, image } = carddata
              currentItem = currentItem + 1
              return (
                <>
                  <Col
                    lg={4}
                    md={6}
                    sm={12}
                    style={{
                      display: currentItem <= itemsToBeShown ? "block" : "none",
                    }}
                  >
                    <center>
                      <EbookScard cardInfo={carddata} />
                    </center>
                  </Col>
                </>
              )
            })}
          </Row>
        )}
      />
      {listCount <= 6 || itemsToBeShown >= listCount ? null : (
        <div className="view-more d-flex justify-content-center p16 fw-600">
          <Buttons
            theme="secondary"
            text="VIEW MORE"
            onClick={() => setItemsToBeShown(itemsToBeShown + 6)}
          />
        </div>
      )}
    </>
  )
}
export default EbookCardGroup
