import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import SVGIcon from "../../components/common/SVGIcon"
import Navigation from "../../components/navigation"
import EbookCardGroup from "../../components/resources/ebookCard"
import Slide from "../../components/utility/slide_content"
import "../../styles/templates/ebooktemp.scss"

const Ebooklanding = props => {
  return (
    <div className="ebook-landing">
      <StaticQuery
        query={graphql`
          query ebookLandingPage {
            SuperOps {
              pages(where: { title: "EbookLanding" }) {
                title
                slug
                subtitle
                linkTitle
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                pageBlock {
                  content {
                    html
                    text
                  }
                }
                pageImages {
                  url
                }
                ctaBoxes {
                  type
                  theme
                  tag
                  customBackgroundColor
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const {
                seo,
                pageBlock,
                subtitle,
                linkTitle,
                pageImages,
                ctaBoxes,
              } = page
              return (
                <div>
                  <Frame seo={seo}>
                    <header>
                      <Navigation page="Home" />
                    </header>
                    <main>
                      <section className="hero">
                        <Slide style={{ "--sal-delay": "0.2s" }}>
                          <Container className="Layout-container">
                            <Row>
                              <Col lg={3} className="d-dsk">
                                <SVGIcon
                                  name="ebook/bulb"
                                  className="prelative bulb-img"
                                />
                              </Col>
                              <Col lg={6}>
                                <div className="hero-conatiner text-center">
                                  <div className="logo-wrap d-flex justify-content-center mb64">
                                    <SVGIcon name="ebook/template-vault" />
                                  </div>
                                  <div className="title mb24">
                                    <h3 className="fw-bold lh-56">
                                      {subtitle}
                                    </h3>
                                  </div>
                                  <div className="subtitle text-grey p16">
                                    <p>
                                      {parse(pageBlock[0].content.text)}
                                      {/* <span className="fw-600">
                                        {"  "}
                                        {parse(pageBlock[1].content.text)}
                                      </span> */}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={3} className="d-dsk">
                                <SVGIcon
                                  name="ebook/drawer-box"
                                  className="prelative drawer-img"
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Slide>
                      </section>
                      <section className="books prelative">
                        <Container className="Layout-container">
                          <div className="card-container prelative">
                            <EbookCardGroup />
                          </div>
                        </Container>
                      </section>
                      <section className="features">
                        <Slide style={{ "--sal-delay": "0.2s" }}>
                          <Container className="Layout-container">
                            <div className="d-flex justify-content-center mb50">
                              <Col lg={7}>
                                <h2 className="text-center fw-bold feat-title">
                                  {parse(pageBlock[2].content.text)}
                                </h2>
                              </Col>
                            </div>
                            <Row className="Content-box-row g-5">
                              {linkTitle.map((featurelist, i) => {
                                return (
                                  <Col lg={4} sm={12}>
                                    <div className="header align-items-start gap-3 d-flex">
                                      <img
                                        src={pageImages[i].url}
                                        alt="ft-icon"
                                      />
                                      <div className="p16 text-grey">
                                        <p>{featurelist}</p>
                                      </div>
                                    </div>
                                  </Col>
                                )
                              })}
                            </Row>
                          </Container>
                        </Slide>
                      </section>
                      <section className="template-cta career-cta">
                        <Slide delay="200">
                          <Container className="Layout-container">
                            <Row className="gy-4">
                              <Col lg={6}>
                                <CTA
                                  data={[ctaBoxes[0]]}
                                  lgLeft={11}
                                  lgRight={11}
                                  className="Layout-container"
                                />
                              </Col>
                              <Col lg={6}>
                                <CTA
                                  data={[ctaBoxes[1]]}
                                  lgLeft={11}
                                  lgRight={11}
                                  className="cta-right mt0 Layout-container"
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Slide>
                      </section>
                    </main>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </div>
  )
}
export default Ebooklanding
